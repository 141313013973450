import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './i18next';
import UserAuthProvider from 'contexts/userAuthContext';
import IntlProvider from 'contexts/intlContext';
import { Loading } from 'ag-ems-ui-library';
import InternetStatus from 'components/InternetStatus';
// components
const BookingPage = lazy(() => import('components/BookingPage/BookingPage'));
const SuccessPage = lazy(() => import('components/SuccessPage/SuccessPage'));
const AccountPage = lazy(() => import('components/Account/Account'));
const SetPasswordPage = lazy(() => import('components/Account/SetPassword'));

const App: React.FC = () => {
  return (
    <div className="App">
      <Suspense fallback={<Loading color="#012e4f" spinner="ship" />}>
        <InternetStatus />
        <Router>
          <IntlProvider>
            <UserAuthProvider>
              <Switch>
                <Route path="/user" component={AccountPage} />
                <Route path="/success/:orderId" component={SuccessPage} />
                <Route
                  path="/resetPassword/:token"
                  component={SetPasswordPage}
                />
                <Route path="/" render={() => <BookingPage />} />
              </Switch>
            </UserAuthProvider>
          </IntlProvider>
        </Router>
      </Suspense>
    </div>
  );
};

export default App;
