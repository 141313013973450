import { initStore } from './store';
import { Order } from '../types';
import { format, getNextDay, now } from 'helpers/dates';

const configureStore = () => {
  const locale = localStorage.getItem('i18nextLng');
  const startDate = format(now, 'yyyy-MM-dd');
  const returnDate = format(getNextDay(now), 'yyyy-MM-dd');

  const initialState = {
    tripId: null,
    returnTripId: null,
    tripNameId: null,
    returnTripNameId: null,
    tripName: null,
    returnTripName: null,
    adults: {
      count: 1
    },
    children: {
      count: 0
    },
    babies: {
      count: 0
    },
    dogs: {
      count: 0
    },
    bicycle: {
      count: 0
    },
    withBicycleUpgrade: false,
    handcart: {
      count: 0
    },
    upgrade: {
      withComfortUpgrade: false,
      withCaptainUpgrade: false,
      outwardUpgrade: null,
      returnUpgrade: null
    },
    gastro: {},
    returnGastro: {},
    familyOffer: '',
    fromPort: null,
    toPort: null,
    tripDate: startDate,
    craftType: null,
    craftTypeId: null,
    tripType: [],
    returnFromPort: null,
    returnToPort: null,
    returnTripDate: returnDate,
    returnCraftType: null,
    returnCraftId: null,
    returnTripType: [],
    withVehicle: false,
    tripWithVehiclePossible: false,
    returnTripWithVehiclePossible: false,
    excursion: false,
    withReturn: true,
    totalPrice: {
      price: null
    },
    address: {
      city: '',
      companyName: '',
      firstName: '',
      lastName: '',
      postalCode: '',
      street: '',
      country: !!locale && locale === 'nl' ? '528' : '276',
      titleId: ''
    },
    paymentType: 'paypal',
    userId: null,
    withParking: false,
    parking: {
      id: null,
      licensePlate: ''
    },
    specialTrip: false,
    captainCapacity: null,
    returnCaptainCapacity: null
  };

  const actions = {
    UPDATE_ORDER: (curState: Order, payload: Order) => {
      const newState = { ...curState, ...payload };
      sessionStorage.setItem('ob-order', JSON.stringify(newState));
      return newState;
    },
    DELETE_ORDER: () => {
      sessionStorage.removeItem('ob-order');
      return initialState;
    }
  };
  initStore(actions, initialState);
};

export default configureStore;
