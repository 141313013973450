import React, {
  useState,
  useEffect,
  useContext,
  createContext,
  useCallback
} from 'react';
import axios from 'axios';
import { API_URL } from 'configs/restAPI';
import { APP_URL } from 'configs/app';
import { useTranslation } from 'react-i18next';
import { setCookie } from 'helpers/cookies';

type User = {
  auth: {
    token: string;
    refreshToken: string;
  };
  id: number;
};

type FieldErrorListType = {
  field: string;
  messageList: [string];
};

type UserAuthContextState = {
  user: User | null;
  signin: (email: string, password: string) => any;
  signout: () => void;
  resetPassword: (email: string) => any;
  refreshToken: () => void;
  checkEmail: (email: string) => any;
  loading: boolean;
  error: string;
};

export const UserAuthContext = createContext<UserAuthContextState>({
  user: null,
  signin: (email: string, password: string) => {},
  signout: () => {},
  resetPassword: (email: string) => {
    return true;
  },
  refreshToken: () => {},
  checkEmail: (email: string) => {
    return true;
  },
  loading: false,
  error: ''
});

const { Provider } = UserAuthContext;

const UserAuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children
}) => {
  const auth: UserAuthContextState = useProvideUserAuth();
  return <Provider value={auth}>{children}</Provider>;
};

export const useUserAuth = () => {
  return useContext(UserAuthContext);
};

function useProvideUserAuth() {
  const [user, setUser] = useState<User | null>(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const signin = async (email: string, password: string) => {
    setLoading(true);
    setError('');
    return await axios
      .post(
        API_URL + '/login',
        `username=${encodeURIComponent(email)}&password=${encodeURIComponent(
          password
        )}&grant_type=password`,
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
          }
        }
      )
      .then(response => {
        if (response.data) {
          const account = {
            auth: {
              token: response.data['access_token'],
              refreshToken: response.data['refresh_token']
            },
            id: response.data['user_id']
          };
          setUser(account);
          localStorage.setItem('ob-account', JSON.stringify(account));
          setCookie('user_id', account.id, 60 * 24 * 365 * 2);
          setLoading(false);
          return response;
        }
      })
      .catch(error => {
        console.error(error);
        if (error.response && error.response.data) {
          setError(error.response.data.error);
        } else setError(error.toString());
        setLoading(false);
        return { error: error };
      });
  };

  const refreshToken = async () => {
    if (!user) return;
    await axios
      .post(
        API_URL + '/oauth/token',
        `refresh_token=${user.auth.refreshToken}&grant_type=refresh_token`,
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
          }
        }
      )
      .then(response => {
        if (response.data) {
          const account = {
            auth: {
              token: response.data['access_token'],
              refreshToken: response.data['refresh_token']
            },
            id: response.data['user_id']
          };
          setUser(account);
          localStorage.setItem('ob-account', JSON.stringify(account));
        }
      })
      .catch(e => {
        signout();
        console.error(e);
      });
  };

  const signout = () => {
    setUser(null);
    localStorage.removeItem('ob-account');
    sessionStorage.removeItem('ob-user');
    sessionStorage.removeItem('ob-newsletter');
    setCookie('user_id', '', -1);
  };

  const resetPassword = async (email: string) => {
    setLoading(true);
    setError('');
    return await axios
      .post(
        API_URL + '/user/password/send',
        {
          email,
          urlTemplate: `${APP_URL}/resetPassword/{token}`
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      .then(() => {
        setLoading(false);
        return true;
      })
      .catch(e => {
        setLoading(false);
        if (e.response.data && e.response.data.userMessage) {
          setError(e.response.data.userMessage);
          return e.response.data.userMessage;
        }
        setError(e.toString());
        return e.toString();
      });
  };

  const checkEmail = useCallback(async (email: string) => {
    setLoading(true);
    return await axios
      .post(
        API_URL + '/user/validate',
        { email },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      .then(() => {
        setLoading(false);
        return null;
      })
      .catch(e => {
        setLoading(false);
        if (e.response.data && e.response.data.fieldErrorList) {
          const emailErrors = e.response.data.fieldErrorList.filter(
            (item: FieldErrorListType) => item.field === 'email'
          );
          if (emailErrors.length > 0) {
            return t('error.emailExist');
            // return emailErrors[0].messageList[0];
          }
        }
        return null;
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const localUser = localStorage.getItem('ob-account');
    if (localUser) {
      setUser(JSON.parse(localUser));
    }
  }, []);

  return {
    user,
    signin,
    signout,
    resetPassword,
    refreshToken,
    checkEmail,
    error,
    loading
  };
}

export default UserAuthProvider;
