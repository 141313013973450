export const setCookie = (
  cookieName: string,
  cookieValue: string,
  minutesToExpire: number
) => {
  let date = new Date();
  date.setTime(date.getTime() + minutesToExpire * 60 * 1000);
  document.cookie =
    cookieName + '=' + cookieValue + '; expires=' + date.toUTCString();
};
export const getCookie = (cookieName: string) => {
  const name = cookieName + '=';
  const allCookieArray = document.cookie.split(';');
  let value = null;
  allCookieArray.forEach((item: string) => {
    const temp = item.trim();
    if (temp.indexOf(name) === 0)
      value = temp.substring(name.length, temp.length);
  });
  return value;
};
