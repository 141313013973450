import {
  format as _format,
  differenceInDays,
  differenceInMinutes,
  differenceInHours,
  differenceInCalendarDays
} from 'date-fns';
import { de, nl, enGB as en } from 'date-fns/locale';
import { toDate, utcToZonedTime } from 'date-fns-tz';

const locales: any = { de, nl, en };
export const timeZone = 'Europe/Berlin';
export const now = utcToZonedTime(new Date(), timeZone);

export { differenceInDays, differenceInCalendarDays, utcToZonedTime, toDate };

export const difference = function(
  dateLeft: string | Date,
  dateRight: string | Date
) {
  if (typeof dateLeft === 'string') dateLeft = new Date(dateLeft);
  if (typeof dateRight === 'string') dateRight = new Date(dateRight);
  return differenceInMinutes(dateLeft, dateRight);
  // const diffMin = differenceInMinutes(dateLeft, dateRight);
  // const min = diffMin % 60;
  // return `${Math.round(diffMin / 60)}:${min < 10 ? '0' + min : min}`;
};

export const differenceFromNowInHours = function(date: string | Date) {
  if (typeof date === 'string') date = new Date(date);
  return differenceInHours(date, new Date());
};

export const format = function(
  date: string | Date,
  formatStr: string,
  currentLocale?: string
) {
  const locale = !!currentLocale
    ? currentLocale
    : localStorage.getItem('i18nextLng');
  if (typeof date === 'string') date = toDate(date);
  return _format(date, formatStr, {
    locale: locale ? locales[locale] : locales['de']
  });
};

export const isLater = (date1: string | Date, date2: string | Date) => {
  return new Date(date1).getTime() > new Date(date2).getTime();
};

export const getNextDay = (_date: string | Date) => {
  const date = new Date(_date);
  date.setDate(date.getDate() + 1);
  return date;
};

export const getDateInXDay = (_date: string | Date, count: number) => {
  const date = new Date(_date);
  date.setDate(date.getDate() + count);
  return date;
};

export const isSameDay = (d1: Date, d2: Date) => {
  return (
    d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate()
  );
};

export const getNextAvailableDate = (
  offWeekDays: number[],
  _date: Date,
  startDate: Date,
  endDate: Date
) => {
  if (isLater(_date, endDate)) return null;
  const date = isLater(_date, startDate) ? _date : startDate;

  let dateWeekDay = date.getDay();
  if (dateWeekDay === 0) dateWeekDay = 7;

  let diffToNextAvailableDay = null;

  for (let i = dateWeekDay; i < 8; i++) {
    if (!offWeekDays.includes(i)) {
      diffToNextAvailableDay = i - dateWeekDay;
      break;
    }
  }
  if (diffToNextAvailableDay === null) {
    for (let i = 1; i < dateWeekDay; i++) {
      if (!offWeekDays.includes(i)) {
        diffToNextAvailableDay = i + dateWeekDay;
        break;
      }
    }
  }
  if (diffToNextAvailableDay === null) {
    if (!isSameDay(_date, date)) return date;
    else return null;
  } else return getDateInXDay(date, diffToNextAvailableDay);
};
