import React, { useState, useContext, createContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

type LanguageType = 'de' | 'nl' | 'en';

type IntlContextState = {
  language: LanguageType;
  changeLanguage: (language: LanguageType) => void;
};

export const IntlContext = createContext<IntlContextState>({
  language: 'de',
  changeLanguage: (language: LanguageType) => {}
});

const { Provider } = IntlContext;

const IntlProvider: React.FC<{ children: React.ReactNode }> = ({
  children
}) => {
  const state: IntlContextState = useProvideIntl();
  return <Provider value={state}>{children}</Provider>;
};

export const useIntl = () => {
  return useContext(IntlContext);
};

const getCurrentLocale = () => {
  const locale = localStorage.getItem('i18nextLng');
  return locale ? (locale as LanguageType) : 'de';
};

function useProvideIntl() {
  const [language, setLanguage] = useState(getCurrentLocale());
  const { i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(language);
    document.documentElement.setAttribute('lang', language);
  }, [language, i18n]);

  const changeLanguage = (selectedValue: LanguageType) => {
    setLanguage(selectedValue);
    localStorage.setItem('i18nextLng', selectedValue);
  };

  return {
    language,
    changeLanguage
  };
}

export default IntlProvider;
