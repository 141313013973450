import styles from './InternetStatus.module.css';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function InternetStatus() {
  const { t } = useTranslation();
  const [status, setStatus] = useState(true);

  const checkStatus = useRef(() => {
    setStatus(navigator.onLine);
  }).current;

  useEffect(() => {
    checkStatus();

    window.addEventListener('online', checkStatus);
    window.addEventListener('offline', checkStatus);

    return () => {
      window.removeEventListener('online', checkStatus);
      window.removeEventListener('offline', checkStatus);
    };
  }, []);

  return !status ? (
    <div className={styles.InternetStatus} role="status">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 640 512"
        fill="white"
        width={20}
        height={20}
      >
        <path d="M129.5 264c-13.25 11.72-14.5 31.94-2.812 45.19C138.5 322.4 158.7 323.7 171.9 312C188.9 296.9 208.1 284.9 228.5 275.8l-55.39-43.41C157.7 241.5 143.1 252 129.5 264zM326.6 352.7c-2.203-.2285-4.345-.6829-6.608-.6829c-35.35 0-64 28.66-64 64.01s28.66 64.01 64 64.01c35.34 0 64-28.66 64-64.01c0-7.564-1.545-14.71-3.956-21.44L326.6 352.7zM9.838 156.7c-12.75 12.25-13.16 32.5-.9375 45.25c12.22 12.78 32.47 13.12 45.25 .9375c12.48-11.98 25.86-22.66 39.61-32.73L41.44 129.2C30.56 137.8 19.99 146.1 9.838 156.7zM364.9 260.7C402.1 268.5 438.6 285.9 468.1 312C474.2 317.4 481.8 320 489.3 320c8.844 0 17.66-3.656 24-10.81C524.1 295.9 523.7 275.7 510.5 264C457.9 217.6 390.2 192 319.1 192C306.9 192 293.9 193.2 281 194.1L186.1 120.6C228.5 104.7 273.6 96 319.1 96c99.59 0 194 37.97 265.8 106.9c6.219 5.938 14.19 8.906 22.16 8.906c8.406 0 16.81-3.281 23.09-9.844c12.22-12.75 11.81-33-.9375-45.25C546.3 76.28 436.2 32 319.1 32C252.7 32 187.7 47.19 128.4 75.35L38.81 5.111C34.41 1.673 29.19 0 24.03 0C16.91 0 9.84 3.158 5.121 9.189c-8.188 10.44-6.37 25.53 4.068 33.7L601.2 506.9c10.5 8.203 25.57 6.328 33.69-4.078c8.188-10.44 6.37-25.53-4.068-33.7L364.9 260.7z" />
      </svg>
      <span>{t('offlineMessage')}</span>
    </div>
  ) : null;
}
