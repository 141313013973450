import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

// stores
import configureOrderStore from './store/order-store';
configureOrderStore();

ReactDOM.render(<App />, document.getElementById('root'));
serviceWorker.unregister();

// for GTM
declare global {
  interface Window {
    dataLayer: any;
    google_optimize?: any;
  }
}
window.dataLayer = window.dataLayer || [];
