import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // lng: 'de',
    backend: {
      loadPath: '/assets/i18n/{{ns}}/{{lng}}.json',
      addPath: '/assets/i18n/{{ns}}/add/{{lng}}.json'
    },
    fallbackLng: 'de',
    supportedLngs: ['de', 'nl', 'en'],
    cleanCode: true,
    debug: false,
    allowMultiLoading: true,
    ns: ['translations', process.env.REACT_APP_CLIENT_KEY],
    defaultNS: 'translations',
    keySeparator: '.',
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
